import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { ResponseModel } from "../models/response.model";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  constructor(private service: DataService) {}

  // upload  image
  uploadImage(data: any): Observable<ResponseModel> {
    let url = "/ClassKeeperApi/Resource/aws-s3/image";
    return this.service.upload(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // update image
  updateImage(data: any): Observable<ResponseModel> {
    let url = "/ClassKeeperApi/Resource/aws-s3/image";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response.body;
      })
    );
  }

  // helper
  toFormData(model): FormData {
    const formData = new FormData();

    Object.keys(model).forEach((key) => {
      formData.append(key, model[key]);
    });

    return formData;
  }

  // delete image
  deleteImage(data: number): Observable<ResponseModel> {
    let url = "/ClassKeeperApi/Photo/delById/" + data;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response.body;
      })
    );
  }

  // get image from url
  imageUrlToBase64 = async (url) => {
    console.log("Downloading image...");
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });

    return result;
  };
}
